import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";

/**
 * shared service used for redirect with query params
 *
 * @export
 * @class RedirectService
 */
@Injectable({
  providedIn: "root",
})
export class RedirectService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  /**
   * navigate to url with params or redirect url
   *
   * @returns {Subscription}
   * @memberof RedirectService
   */
  getRoute(): Subscription {
    return this.activatedRoute.queryParams
      .pipe(first())
      .subscribe((params) => this.redirect(params.redirectURL));
  }

  redirect(redirectURL: string): void {
    if (redirectURL && redirectURL.includes("?")) {
      const params = this.getQueryParams(redirectURL);
      this.router.navigate([redirectURL.split("?")[0]], params);
    } else {
      this.router.navigate([redirectURL || "/"]);
    }
  }
  /**
   * method takes queryParams as string and convert it to object
   *
   * @private
   * @param {string} locationSearch
   * @returns {queryParams: obj}
   * @memberof RedirectService
   */
  private getQueryParams(locationSearch: string): any {
    const params = {};
    if (locationSearch) {
      locationSearch = locationSearch.split("?")[1];
      const split = locationSearch.split("&");
      for (const iterator of split) {
        const propName = iterator.split("=")[0];
        const propValue = iterator.split("=")[1];
        params[propName] = propValue;
      }
    }
    return { queryParams: { ...params } };
  }
}
