// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://qupil-dev-app-itwdaqrulq-oa.a.run.app/api',
  // please uncomment the below line if you would like to work on local development
  // apiURL: "http://localhost:3000/api",
  createLectureFeatureFlag: true,
  appId: "7b4c4b38fb1545b8a76627bd64faf244",
  firebase: {
    apiKey: "AIzaSyDz7JfbialMrCcMnVExt8in91Ka5TS8NoQ",
    authDomain: "qupil-345111.firebaseapp.com",
    databaseURL:
      "https://qupil-345111-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "qupil-345111",
    storageBucket: "qupil-345111.appspot.com",
    messagingSenderId: "60148088351",
    appId: "1:60148088351:web:469a3f4f75cf598d665f2a",
    measurementId: "G-6YX762CE86",
  },
};
/*

 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
