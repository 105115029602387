import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { ORGANIZATION_ID } from "src/app/constants";

import { AuthService, RedirectService } from "../../services";
import * as fromActions from "../actions";
import { getNotificationFirebaseToken } from "../selectors";
import { IRootState } from "./../reducers";

// import * as fromModels from '../../models';
@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private redirectService: RedirectService,
    private store: Store<IRootState>
  ) {}

  // Login Effects
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.login),
      withLatestFrom(this.store.pipe(select(getNotificationFirebaseToken))),
      switchMap(([{ payload }, firebaseToken]) =>
        this.authService
          .login({ ...payload, firebaseToken, deviceId: "web" })
          .pipe(
            map((user) =>
              fromActions.loginSuccess({
                payload: { user: user, email: payload.email },
              })
            ),
            catchError((error) =>
              of(fromActions.loginFailure({ payload: error && error.error }))
            )
          )
      )
    )
  );

  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.loginSuccess),
        tap(({ payload: { user, email } }) => {
          if (
            user.isEmailVerified ||
            user.organizations[0].role.type === "MEMBER"
          ) {
            if (user.organizations[0].role.type === "MEMBER") {
              this.router.navigate(["/member"], {
                queryParams: { action: "login" },
              });
            } else {
              this.authService.setRefreshToken(user.refreshToken);
              this.authService.setAccessToken(user.accessToken);
              // this.authService.setOrganizationId(user.organizations[0].organization.id);
              this.redirectService.getRoute();
            }
          } else {
            this.router.navigate(["/auth/verify"], {
              queryParams: { email: email },
            });
          }

          // this.snackBar.open('You have successfully logged in', '', {
          //   duration: 3000,
          //   panelClass: ['success-snackbar'],
          // });
        })
      );
    },
    { dispatch: false }
  );

  loginFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.loginFailure),
        tap(({ payload }) =>{
          if ( payload.errors?.facebook) {
            // Redirect the user to the sign-up page
            this.router.navigate(['/auth/signup']);
          }
          this.snackBar.open(`Failed to login, ${payload.message}`, "", {
            duration: 3000,
            panelClass: ["failure-snackbar"],
          })}
        )
      );
    },
    { dispatch: false }
  );

  // Signup Effects
  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.signup),
      switchMap(({ payload }) =>
        this.authService.signup(payload).pipe(
          map((user) => fromActions.signupSuccess({ payload: user })),
          catchError((error) =>
            of(
              fromActions.signupFailure({
                payload: error && error.error,
              })
            )
          )
        )
      )
    )
  );

  signupSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.signupSuccess),
        tap(({ payload }) => {
          this.snackBar.open("You have successfully signed up", "", {
            duration: 3000,
            panelClass: ["success-snackbar"],
          });

          if (payload.isEmailVerified) {
            this.authService.setRefreshToken(payload.refreshToken);
            this.authService.setAccessToken(payload.accessToken);
            // this.authService.setOrganizationId(payload.organizations[0].organization.id);
            this.redirectService.getRoute();
          } else {
            this.router.navigate(["/auth/verify"], {
              queryParams: { email: payload.email },
            });
          }
        })
      );
    },
    { dispatch: false }
  );

  signupFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.signupFailure),
        tap(({ payload }) =>
          this.snackBar.open(`Failed to signup, ${payload.message}`, "", {
            duration: 3000,
            panelClass: ["failure-snackbar"],
          })
        )
      );
    },
    { dispatch: false }
  );

  // Signup with invitation Effects
  signupWithInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.signupWithInvitation),
      switchMap(({ payload }) =>
        this.authService.signupWithInvitation(payload).pipe(
          map((user) =>
            fromActions.signupWithInvitationSuccess({ payload: user })
          ),
          catchError((error) =>
            of(
              fromActions.signupWithInvitationFailure({
                payload: error && error.error,
              })
            )
          )
        )
      )
    )
  );

  signupWithInvitationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.signupWithInvitationSuccess),
        tap(({ payload }) => {
          this.authService.setRefreshToken(payload.refreshToken);
          this.authService.setAccessToken(payload.accessToken);
          // this.authService.setOrganizationId(payload.organizations[0].organization.id);
          this.snackBar.open("You have successfully signed up", "", {
            duration: 3000,
            panelClass: ["success-snackbar"],
          });
          this.router.navigate(["/"]);
        })
      );
    },
    { dispatch: false }
  );

  signupWithInvitationFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.signupWithInvitationFailure),
        tap(({ payload }) =>
          this.snackBar.open(`Failed to signup, ${payload.message}`, "", {
            duration: 3000,
            panelClass: ["failure-snackbar"],
          })
        )
      );
    },
    { dispatch: false }
  );

  // Logout effect
  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.logout),
      map(() => {
        return fromActions.logoutOrganization({ payload: ORGANIZATION_ID });
      })
    );
  });

  // Logout organization Effects
  logoutOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.logoutOrganization),
      switchMap(({ payload }) =>
        this.authService.logoutOrganization(payload).pipe(
          map(() => fromActions.logoutOrganizationSuccess()),
          catchError((error) =>
            of(
              fromActions.logoutOrganizationFailure({
                payload: error && error.error,
              })
            )
          )
        )
      )
    )
  );

  logoutOrganizationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.logoutOrganizationSuccess),
        tap(() => {
          this.authService.removeRefreshToken();
          this.authService.removeAccessToken();

          this.router.navigate(["/auth"]);
        })
      );
    },
    { dispatch: false }
  );

  logoutOrganizationFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.logoutOrganizationFailure),
        tap(({ payload }) =>
          this.snackBar.open(`Failed to logout, ${payload.message}`, "", {
            duration: 3000,
            panelClass: ["failure-snackbar"],
          })
        )
      );
    },
    { dispatch: false }
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => fromActions.loadUser())
    )
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadUser),
      filter(() => !!this.authService.getAccessToken()),
      switchMap(() =>
        this.authService.getCurrentUser().pipe(
          map((data) => fromActions.loadUserSuccess({ payload: data })),
          catchError((error) =>
            of(fromActions.loadUserFailure({ payload: error && error.error }))
          )
        )
      )
    )
  );

  // Forget Password Effects
  forgetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.forgetPassword),
      switchMap(({ payload }) =>
        this.authService.forgetPassword(payload).pipe(
          map(() =>
            fromActions.forgetPasswordSuccess({ payload: payload.email })
          ),
          catchError((error) =>
            of(
              fromActions.forgetPasswordFailure({
                payload: error && error.error,
              })
            )
          )
        )
      )
    )
  );

  forgetPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.forgetPasswordSuccess),
        tap(({ payload }) => {
          this.router.navigate(["/auth/check-reset-password-email"], {
            queryParams: {
              emailAddress: payload,
            },
          });

          this.snackBar.open("Check your email", "", {
            duration: 3000,
            panelClass: ["success-snackbar"],
          });
        })
      );
    },
    { dispatch: false }
  );

  forgetPasswordFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.forgetPasswordFailure),
        tap(({ payload }) =>
          this.snackBar.open(
            `Failed to forgetPassword, ${payload.message}`,
            "",
            {
              duration: 3000,
              panelClass: ["failure-snackbar"],
            }
          )
        )
      );
    },
    { dispatch: false }
  );

  // Reset Password Effects
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetPassword),
      switchMap(({ payload }) =>
        this.authService.resetPassword(payload.dto).pipe(
          map(() => fromActions.resetPasswordSuccess({ payload })),
          catchError((error) =>
            of(
              fromActions.resetPasswordFailure({
                payload: error && error.error,
              })
            )
          )
        )
      )
    )
  );

  resetPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.resetPasswordSuccess),
        tap(({ payload }) => {
          if (payload.userType === "MEMBER") {
            this.router.navigate(["/member"], {
              queryParams: { action: "resetPassword" },
            });
          } else {
            this.router.navigate(["/auth/login"]);
          }

          this.snackBar.open(
            "Your password has been reset successfully, you can login",
            "",
            {
              duration: 3000,
              panelClass: ["success-snackbar"],
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  resetPasswordFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.resetPasswordFailure),
        tap(({ payload }) =>
          this.snackBar.open(
            `Failed to reset password, ${payload.message}`,
            "",
            {
              duration: 3000,
              panelClass: ["failure-snackbar"],
            }
          )
        )
      );
    },
    { dispatch: false }
  );

  // Verify Effects
  verify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.verify),
      switchMap(({ payload }) =>
        this.authService.verify(payload).pipe(
          map((user) => fromActions.verifySuccess({ payload: user })),
          catchError((error) =>
            of(fromActions.verifyFailure({ payload: error && error.error }))
          )
        )
      )
    )
  );

  verifySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.verifySuccess),
        tap(({ payload }) => {
          this.snackBar.open("Your account has been verified", "", {
            duration: 3000,
            panelClass: ["success-snackbar"],
          });

          if (payload.organizations[0].role.type === "MEMBER") {
            this.router.navigate(["/member"], {
              queryParams: { action: "verifyAccount" },
            });
          } else {
            this.authService.setRefreshToken(payload.refreshToken);
            this.authService.setAccessToken(payload.accessToken);
            // this.authService.setOrganizationId(payload.organizations[0].organization.id);
            this.router.navigate(["/auth/login"]);
          }
        })
      );
    },
    { dispatch: false }
  );

  verifyFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.verifyFailure),
        tap(({ payload }) => {
          this.snackBar.open(payload.message, "", {
            duration: 3000,
            panelClass: ["failure-snackbar"],
          });

          // this.router.navigate(['/auth']);
        })
      );
    },
    { dispatch: false }
  );

  // update
  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateUser),
      switchMap(({ payload }) =>
        this.authService.updateUser(payload).pipe(
          map((user) => fromActions.updateUserSuccess({ payload: user })),
          catchError((error) =>
            of(fromActions.updateUserFailure({ payload: error && error.error }))
          )
        )
      )
    )
  );

  updateUserFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.updateUserFailure),
        tap(({ payload }) =>
          this.snackBar.open(`Failed to update user, ${payload.message}`, "", {
            duration: 3000,
            panelClass: ["failure-snackbar"],
          })
        )
      );
    },
    { dispatch: false }
  );

  resendVerificationCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendVerificationCode),
      switchMap(({ payload }) =>
        this.authService.resendVerificationCode(payload.email).pipe(
          map((user) => fromActions.resendVerificationCodeSuccess()),
          catchError((error) =>
            of(
              fromActions.resendVerificationCodeFailure({
                payload: error && error.error,
              })
            )
          )
        )
      )
    )
  );

  resendVerificationCodeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.resendVerificationCodeSuccess),
        tap(() => {
          this.snackBar.open(
            "Verification code is sent successfully ... Please check your mail",
            "",
            {
              duration: 3000,
              panelClass: ["success-snackbar"],
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  resendVerificationCodeFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.resendVerificationCodeFailure),
        tap(({ payload }) => {
          this.snackBar.open(payload.message, "", {
            duration: 7000,
            panelClass: ["failure-snackbar"],
          });
        })
      );
    },
    { dispatch: false }
  );

  // Verify update email Effects
  verifyUpdateEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.verifyUpdateEmail),
      switchMap(({ payload }) =>
        this.authService.verifyUpdateEmail(payload).pipe(
          map((user) =>
            fromActions.verifyUpdateEmailSuccess({ payload: user })
          ),
          catchError((error) =>
            of(
              fromActions.verifyUpdateEmailFailure({
                payload: error && error.error,
              })
            )
          )
        )
      )
    )
  );

  verifyUpdateEmailSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.verifyUpdateEmailSuccess),
        tap(({ payload }) => {
          this.snackBar.open("Your account has been verified", "", {
            duration: 3000,
            panelClass: ["success-snackbar"],
          });

          if (payload.organizations[0].role.type === "MEMBER") {
            this.router.navigate(["/member"], {
              queryParams: { action: "verifyAccount" },
            });
          } else {
            this.authService.setRefreshToken(payload.refreshToken);
            this.authService.setAccessToken(payload.accessToken);
            // this.authService.setOrganizationId(payload.organizations[0].organization.id);
            this.router.navigate(["/auth/login"]);
          }
        })
      );
    },
    { dispatch: false }
  );

  verifyUpdateEmailFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.verifyUpdateEmailFailure),
        tap(({ payload }) => {
          this.snackBar.open(payload.message, "", {
            duration: 3000,
            panelClass: ["failure-snackbar"],
          });

          // this.router.navigate(['/auth']);
        })
      );
    },
    { dispatch: false }
  );

  resendUpdateEmailVerificationCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendUpdateEmailVerificationCode),
      switchMap(({ payload }) =>
        this.authService.resendUpdateEmailVerificationCode(payload.email).pipe(
          map((user) => fromActions.resendUpdateEmailVerificationCodeSuccess()),
          catchError((error) =>
            of(
              fromActions.resendUpdateEmailVerificationCodeFailure({
                payload: error && error.error,
              })
            )
          )
        )
      )
    )
  );

  resendUpdateEmailVerificationCodeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.resendUpdateEmailVerificationCodeSuccess),
        tap(() => {
          this.snackBar.open(
            "Verification code is sent successfully ... Please check your mail",
            "",
            {
              duration: 3000,
              panelClass: ["success-snackbar"],
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  resendUpdateEmailVerificationCodeFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.resendUpdateEmailVerificationCodeFailure),
        tap(({ payload }) => {
          this.snackBar.open(payload.message, "", {
            duration: 7000,
            panelClass: ["failure-snackbar"],
          });
        })
      );
    },
    { dispatch: false }
  );
}
