import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";

import { DOCUMENT } from "@angular/common";
import { AuthService, MessagingService } from "./services";
import {
  VCFO_ORGANIZATION_ID,
  QUPIL_ORGANIZATION_ID,
  ORGANIZATION_ID,
  QUPIL_LANGUAGE_EN,
  VCFO_LANGUAGE_EN,
} from "./constants";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private afMessaging: AngularFireMessaging,
    private message: MessagingService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private authService: AuthService,
    private router: Router
  ) {}

  async ngOnInit() {
    // get base url
    // for example: vcfo.net
    const baseUrl = window.location.origin;

    // if base url includes vcfo
    // set vcfo organization id
    // use "localhost" to test vcfo in your local
    if (
      baseUrl.includes("vcfo") &&
      this.authService.getOrganizationId() !== VCFO_ORGANIZATION_ID
    ) {
      this.authService.setOrganizationId(VCFO_ORGANIZATION_ID);

      // if not json strings stored with local storage
      if (!localStorage.getItem("en"))
        this.authService.setLanguage("en", VCFO_LANGUAGE_EN);

      window.location.reload();
    } else if (
      !baseUrl.includes("vcfo") &&
      this.authService.getOrganizationId() !== QUPIL_ORGANIZATION_ID
    ) {
      // else set default organization id
      this.authService.setOrganizationId(QUPIL_ORGANIZATION_ID);

      // if not json strings stored with local storage
      if (!localStorage.getItem("en"))
        this.authService.setLanguage("en", QUPIL_LANGUAGE_EN);

      window.location.reload();
    }

    if (this.authService.getOrganizationId() == QUPIL_ORGANIZATION_ID)
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          // import botpress files
          // integrate with botpress service
          let botpressScriptInject = this._renderer2.createElement("script");
          botpressScriptInject.src = `https://cdn.botpress.cloud/webchat/v0/inject.js`;
          botpressScriptInject.setAttribute("id", "botpressScriptInject");
          let botpressScriptConfig = this._renderer2.createElement("script");
          botpressScriptConfig.src = `https://mediafiles.botpress.cloud/a55ed7c1-030a-4fa1-8817-2c2915a0fcb8/webchat/config.js`;
          botpressScriptConfig.defer = true;
          botpressScriptConfig.setAttribute("id", "botpressScriptConfig");
          if (e.url.includes("/auth/login") || e.url == "/auth") {
            this._renderer2.appendChild(
              this._document.body,
              botpressScriptInject
            );
            this._renderer2.appendChild(
              this._document.body,
              botpressScriptConfig
            );
            setTimeout(() => {
              if (
                !document.querySelectorAll(
                  '[href="https://cdn.botpress.cloud/webchat/v0/inject.css"]'
                ).length
              ) {
                this.router.navigate(["/auth"]);
              }
            }, 1000);
          } else {
            this._renderer2.removeChild(
              this._document.body,
              document.getElementById("botpressScriptConfig")
            );
            this._renderer2.removeChild(
              this._document.body,
              document.getElementById("botpressScriptInject")
            );
            this._renderer2.removeChild(
              this._document.body,
              document.getElementById("bp-web-widget-container")
            );
          }
        }
      });

    this.message.requestPermission();
    this.afMessaging.requestPermission.subscribe((data) => {});
    this.message.receiveMessage();

    // get images and favicon and logos
    await this.authService.getLogoAndImages(ORGANIZATION_ID).toPromise();

    // get languages strings and save it in local storage
    await this.authService.getLanguages(ORGANIZATION_ID).toPromise();

    // after get icon from backend
    // assign this icon to brawser tab
    // by link tag
    var link = document.createElement("link");
    link.setAttribute("rel", "icon");
    link.setAttribute("type", "image/x-icon");
    link.setAttribute("href", localStorage.getItem("logo"));
    document.head.appendChild(link);
  }
}
